'use client';
import {Loader} from '@googlemaps/js-api-loader';
import {useEffect, useState} from 'react';

export const GoogleMapLoader = ({children}) => {
  const [isMapLoaded, setIsMapLoaded] = useState(null);
  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.importLibrary('maps').then(({Map}) => {
      new Map(document.getElementById('map'), {
        center: {lat: -34.397, lng: 150.644},
        zoom: 8,
      });

      setIsMapLoaded(true);
    });
  }, []);
  return (
    <>
      <div id='map'></div>
      {isMapLoaded && children}
    </>
  );
};
