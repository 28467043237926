'use client';
import React, {useEffect} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import RTL from '@/shared/components/dashboard/layout/shared/customizer/RTL';
import {ThemeSettings} from '@/shared/utils/theme/Theme';
import {useSelector} from 'react-redux';
import {Toaster} from 'react-hot-toast';
import {AppRouterCacheProvider} from '@mui/material-nextjs/v14-appRouter';
import '@/shared/utils/i18n';
import {GoogleMapLoader} from '@/shared/providers/GoogleMapLoader';
import {AuthGuard} from '@/shared/components/auth/AuthGuard';

const MyApp = ({children}) => {
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.customizer);

  return (
    <AuthGuard>
      <AppRouterCacheProvider options={{enableCssLayer: true}}>
        <GoogleMapLoader>
          <ThemeProvider theme={theme}>
            <RTL direction={customizer.activeDir}>
              <CssBaseline />
              {children}
            </RTL>
          </ThemeProvider>
          <Toaster
            position={'top-right'}
            toastOptions={{className: 'react-hot-toast'}}
          />
        </GoogleMapLoader>
      </AppRouterCacheProvider>
    </AuthGuard>
  );
};

export default MyApp;
